import React, { useRef } from 'react'
import styled from '@emotion/styled'

import darumaImg from '@/assets/images/about/daruma.webp'
import { media } from '@/styles/media'
import { useTranslation } from 'react-i18next'
import { motion, useTransform, useViewportScroll } from 'framer-motion'
import KeepScrolling from '@/components/generic/KeepScrolling'
import SubTitle from '../home/Subtitle'

const Wrapper = styled.div`
  padding-top: 96px;
  padding-bottom: 533px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #001b2e;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 36, 61, 0) 0%,
      rgba(0, 36, 61, 0.4) 24.92%,
      rgba(0, 27, 46, 0.8) 100%
    );
    mix-blend-mode: lighten;
    pointer-events: none;
    z-index: 800;
  }

  ${media.tabletLandscapeDown} {
    padding: 48px 0;
  }
`

const KeepScrollingWrapper = styled.div`
  padding-top: 72px;
  z-index: 99;
`

const DarumaWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
`

const DarumaContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  ${media.tabletLandscape} {
    transform: none !important;
  }
`

const TitleContent = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
  width: 100vw;
  overflow: hidden;
  ${media.bigDesktopDown} {
    justify-content: start;
    padding-top: 0;
  }
`

const Kanji = styled(motion.div)`
  z-index: 0;
  color: #002e4e;
  font-family: 'Zen Antique Soft';
  font-weight: 400;
  font-size: 485.5px;
  font-style: normal;
  line-height: 485.5px;
  text-transform: uppercase;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  opacity: 0.7;
  text-align: center;
  display: flex;
  justify-content: center;
  padding-top: 600px;
  ${media.desktopDown} {
    font-size: 100px;
    line-height: 100px;
    margin-top: -70px;
    padding-top: 400px;
  }
`

const Title = styled.h2`
  margin: 0;
  padding-top: 32px;
  z-index: 99;
  color: #f8f5ee;
  white-space: nowrap;
  text-align: center;
  font-family: Inter;
  font-size: 180px;
  font-style: normal;
  font-weight: 500;
  line-height: 180px;
  letter-spacing: -10.8px;

  i {
    font-family: 'Argent CF';
    color: #e2421f;
  }

  ${media.desktopDown} {
    padding-top: 9px;
    font-size: 55.808px;
    font-weight: 500;
    line-height: 55.808px;
    letter-spacing: -3.907px;
  }
`

const Description = styled.h4`
  margin: 0;
  color: #ece4ce;
  z-index: 99;
  text-align: center;
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: 72px;
  letter-spacing: -0.96px;
  padding-top: 72px;
  max-width: calc(100vw - 192px - 400px);
  word-break: keep-all;
  ${media.newLargeDesktopDown} {
    max-width: calc(100vw - 192px);
  }
  ${media.bigDesktopDown} {
    max-width: calc(100vw - 48px);
    height: auto;
  }

  ${media.desktopDown} {
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.44px;
    padding: 100px 24px 0 24px;
  }
`

const Daruma = styled(motion.img)`
  padding-top: 96px;
  width: 100%;
  height: auto;
  z-index: 99;
  position: sticky;
  top: 0;
  ${media.desktopDown} {
    padding: 140px 0;
  }
`

const DarumaSticky = styled.div`
  position: absolute;
  top: 0;
  z-index: 1;
  width: 533px;
  height: 300vh;

  ${media.desktopDown} {
    width: 155px;
  }
`

export default function Hero() {
  const ref = useRef(null)

  const { t } = useTranslation()

  const { scrollY } = useViewportScroll()
  const darumaScale = useTransform(scrollY, [0, 1000], [1, 1.2])
  const darumaY = useTransform(scrollY, (value) => value * 0.2)
  const kanjiY = useTransform(scrollY, (value) => -value * 0.9)

  return (
    <Wrapper ref={ref}>
      <DarumaWrapper>
        <DarumaContent>
          <DarumaSticky>
            <Daruma
              style={{ scale: darumaScale, y: darumaY }}
              src={darumaImg}
            />
          </DarumaSticky>
          <Kanji translate="no" style={{ y: kanjiY }}>
            <span>日本体験</span>
          </Kanji>
        </DarumaContent>
      </DarumaWrapper>
      <TitleWrapper>
        <TitleContent>
          <SubTitle color="#F8F5EE" center translate="no">
            <span>COMPANY PROFILE</span>
            <span>会社概要</span>
          </SubTitle>
          <Title>
            We are <i>ICHIGO</i>
          </Title>
        </TitleContent>

        <Description>{t('about_us.hero_description')}</Description>
        <KeepScrollingWrapper>
          <KeepScrolling />
        </KeepScrollingWrapper>
      </TitleWrapper>
    </Wrapper>
  )
}
